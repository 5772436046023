<template>
	<div :class="themeClass">
		<div class="width-fill">
			<div class="login-t flex-row-align-center">
				<div class="font-weight700 font-size18 pointer"><img :src="logoUrl" style="height: 54px;" /></div>
				<div class="font-color-E30000 font-weight700 font-size14 bg-E30000 flexUD pay-center">支付中心</div>
			</div>
		</div>
		<div style="padding:147px 0;">
			<div class="loginh"></div>
			<div class="login-c">
				<div class="">
					<div class="flex-row-start-start">
						<div class="result-message-box">
							<el-image style="width: 196px; height: 222px" :src="url"></el-image>
						</div>
						<div class="flex-colum result-box">
							<div v-if="payType!=2&&payType" class="title-style">
								<div v-if="payType==1">正在支付中，请稍后前往钱包查询本次交易记录</div>
								<div v-if="payType==3">支付失败</div>
							</div>
							<div v-else class="title-style">
								<div>支付成功</div>
							</div>
							<div class="flex-colum font-color-666" style="line-height: 35px;">
								<div v-if="billNumber!=''&&billNumber!=null">单号:{{billNumber}}</div>
								<div>支付金额:{{amount|NumFormat}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		components: {},
		data() {
			return {
				payType: '', // 1 支付中 2支付成功 3支付失败
				billNumber: null, //单据编号
				amount: '', //金额
				type: '', //是否钱包充值 0 否 1 是
				url: require('../assets/imgs/PaymentSuccessful.png'),
				id: '',
				logoUrl: '',
			};
		},
		created() {},
		mounted() {
			this.id = this.$route.query.id; //单据ID
			this.type = this.$route.query.type; //1现金钱包充值 0 非现金钱包充值
			this.AlreadyPaid()
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		},
		computed: {
			...mapGetters(['getThemeName', "getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			AlreadyPaid() {
				this.ApiRequestPostNOMess('api/mall/ebsale/customer-payment-bill/pay-success-validate', {
						fCustomerPaymentBillID: this.id,
						fAppTypeID: this.fAppTypeID,
						fIsCashRecharge: this.type
					})
					.then(res => {
						console.log("我已支付___完成", res);
						if (this.type == 0) {
							this.getPayResultZFT();
						} else if (this.type == 1) {
							this.getPayResultZJ();
						}
					}, error => {});
			},
			//现金钱包充值结果查询
			getPayResultZJ() {
				this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/get', {
						id: this.id
					})
					.then(res => {
						console.log("获取支付结果___完成", res);
						this.billNumber = res.obj.fGatheringBillNumber;
						this.amount = res.obj.fSumMoney;
						this.payType = res.obj.fPlyingStatus;
					}, error => {});
			},
			//非现金钱包充值获取支付结果
			getPayResultZFT() {
				this.ApiRequestPostNOMess('/api/mall/ebsale/customer-payment-bill/get', {
						id: this.id
					})
					.then(res => {
						console.log("获取支付结果___完成", res);
						this.billNumber = res.obj.fBillNumber;
						this.amount = res.obj.fTotalMoney;
						this.payType = res.obj.fPlyingStatus;
					}, error => {});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.result-box {
		margin-left: 66px;
		margin-top: 24px;
		width: 100%;
	}

	.pay-center {
		width: 69px;
		height: 22px;
		margin-left: 25px;
	}

	.result-message-box {
		width: 196px;
		height: 222px;
		margin-top: 11px;
		margin-left: 47px;
	}

	.title-style {
		color: #3FD09E;
		font-size: 20px;
		font-weight: bold;
		line-height: 35px;
	}

	.login-t {
		font-weight: 700;
		font-size: 18px;
		// line-height: 80px;
		height: 80px;
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		/*垂直居中*/
		background-color: #ffffff;
	}

	.loginh {
		margin-top: 80px;
		background: url(../assets/imgs/payResult.png);
		// background: url(../assets/imgs/payBackground.jpg) ;
		width: 100%;
		height: 570px;
		background-repeat: no-repeat;
		background-size: cover;
		// -webkit-filter:blur(15px);
		// -moz-filter:blur(15px);
		// -o-filter:blur(15px);
		// -ms-filter:blur(15px);
		// filter:blur(15px);
		position: absolute;
		left: 0;
		top: 0;
		z-index: -999;
	}

	.login-c {
		margin: 0 auto;
		width: 900px;
		height: 253px;
		border-radius: 10px;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.149019607843137);
		background-color: #ffffff;
	}

	/* 单选地址 */

	.flexUD {
		display: flex;
		align-items: center;
		/*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/*  */
	.font-color-E30000 {
		color: #e30000;
	}

	.bg-E30000 {
		background-color: #e30000;
		color: #ffffff;
	}

	/*  */
	.buttonStyle {
		width: 160px;
		height: 40px;
		background-color: #ffffff;
		border-radius: 2px;
	}

	// ::v-deep #app .el-checkbox__inner {
	// 	border-radius: 10px;
	// }
</style>
